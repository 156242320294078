<template>
    <transition name="fade">
        <div class="page">
            <div class="secondary-header">
                <div @click="() => { this.$router.push({path: '/channels'})}">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"> <path d="M15 6L9 12L15 18" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                    Channels
                </div>

            </div>

            <div class="space"></div>

            <div class="content-row guides" v-ripple v-for="(k,j) in guides" :key="j">
                <ListGuide :guide="k" />
            </div>

            <span class="loader" v-show="loading"></span>
       </div>

    </transition>
  </template>

  <script>
    const ListGuide = () => import('@/components/ListGuide')

  export default {
    name: 'EPGList',

    components: {
      ListGuide,
    },

    watch: {
        // $route() {
        //   this.load()
        //   this.$nextTick(() => {
        //       window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        //   })
        // }
    },

    data: function(){
      return {
          guides: [],
        loading: true,
        done: false,
          page: 1,
      }
    },

    methods: {
            scroll() {
                if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight && !this.loading) {
                    this.getEPG();
                }
            },

            async getEPG(){
                if(this.done){return}
                this.loading = true;
                const req = await fetch(`${process.env.VUE_APP_SERVER}/all-guides?page=${this.page}`)
                const data = await req.json()
                data.guides.forEach((x) => { this.guides.push(x) })
                if(data.guides.length < 100){
                    this.done = true
                }

                this.page = this.page + 1
                this.loading = false;
          },

    },

    computed: { },

    destroyed(){
        window.removeEventListener('scroll', this.scroll);
    },

    mounted: function(){
      this.getEPG()
            this.$nextTick(() => {
                window.addEventListener('scroll',this.scroll);
            })
        }
  }

  </script>

<style scoped lang="scss">
.secondary-header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 20px 0;
    background-color: rgba(#141414, 0.75);
    backdrop-filter: blur(10px);
    padding-left: 20px;
}
.space{
    height: 70px
}

.content-row.guides{
    margin-bottom: 6px;
}

.page{
    padding-bottom: 50px;
}
.page > div{
    overflow: scroll;
    width: 100vw;
}
.loader {
    position: fixed;
    left: calc(50vw - 12px);
    z-index: 999;
    top: calc(100vh - 90px);
    width: 24px;
    height: 24px;
    border: 2px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>

